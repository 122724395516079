import { List } from '@mui/material';
import { MenuItem as MenuItemType } from './MenuItems';
import MenuItem from './MenuItem';

interface MenuItemsListProps {
  options: MenuItemType[];
}

const MenuItemsList = ({ options }: MenuItemsListProps) => {
  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      byaria-labelled="nested-list-subheader"
    >
      {options.map((option) => (
        <MenuItem menuItem={option} key={option.id} />
      ))}
    </List>
  );
};

export default MenuItemsList;
