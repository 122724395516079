import React, { useEffect, useRef, useState } from 'react';
import {
  Column,
  DataGrid,
  Editing,
  RequiredRule,
  RowDragging,
  Summary,
  TotalItem,
  Toolbar,
  Item,
  Button as Gridbtn,
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { styled } from '@mui/material/styles';
import { api } from 'lib/api/JobApi';

const GridContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'end',
  padding: '10px',
  marginTop: '10px',
}));
const customizeTotalCount = (data) => {
  return `total Count: ${data.value}`;
};
const ScheduleList = (props) => {
  const { jobId } = props;
  const [schedule, setSchedule] = useState();
  const [popupVisible, setPopupVisible] = useState(false);
  const checkScheduleId = useRef();

  useEffect(() => {
    api.getSchedule(jobId).then((res) => {
      setSchedule(res);
    });
  }, []);

  // row add
  const insertRow = (e) => {
    const params = e.data;
    console.log(e.data);
    api.insertSchedule(jobId, params).then((res) => {
      notify({ message: '추가되었습니다.', width: 300 }, 'success', 2000);
      console.log('insert Schedule', res);
    });
  };

  // row update
  const onRowUpdated = async ({ newData, oldData }) => {
    const { isForever, endDate, cronExpression, isUse, name, startDate } = newData;
    const scheduleId = oldData.id;
    const params = {
      endDate: isForever ? '' : endDate,
      scheduleId,
      cronExpression,
      isForever,
      startDate,
      isUse,
      name,
    };
    await api.updateSchedule(jobId, params);
    notify({ message: '저장되었습니다.', width: 300 }, 'success', 2000);
  };

  // row remove
  const onRowRemoved = (e) => {
    const delId = e.data.id;
    try {
      api.delSchedule(delId);
      notify({ message: '삭제되었습니다.', width: 300 }, 'success', 2000);
    } catch (error) {
      console.log(error);
      notify({ message: '실패하였습니다', width: 300 }, 'error', 2000);
    }
  };

  // isForever 체크하면 endDate -> disabled
  const onEditorPreparing = (e) => {
    if (e.row.data.isForever && e.dataField === 'endDate') {
      e.editorOptions.disabled = true;
    }
  };
  // isForever -> value 값에 따라 endDt null
  const setCellValueIsForever = (newData, value, currentRowData) => {
    console.log('value', value, 'currentRowData', currentRowData);
    const { endDate } = currentRowData;
    const defaultDate = value === true ? null : endDate;

    const data = newData;
    data.endDate = defaultDate;
    data.isForever = value;
  };

  // popup open
  const onAddClick = () => {
    setPopupVisible(true);
    // const form = formRef.current?.instance;
  };

  // popup close
  const hidePopup = () => {
    setPopupVisible(false);
    // const form = formRef.current?.instance;
  };

  return (
    <>
      <span> Schedule 목록 </span>
      <GridContainer>
        <DataGrid
          onEditorPreparing={onEditorPreparing}
          height={250}
          dataSource={schedule}
          showBorders
          allowColumnReordering
          showRowLines
          rowAlternationEnabled
          onRowRemoved={onRowRemoved}
          onRowInserting={insertRow}
          onRowUpdating={onRowUpdated}
          onSelectedRowKeysChange={(e) => {
            checkScheduleId.current = e;
          }}
        >
          {/* <Selection mode="multiple" /> */}
          <RowDragging autoScroll />
          <Editing mode="row" allowUpdating allowDeleting allowAdding />
          <Toolbar>
            <Item location="before" name="addRowButton" />
          </Toolbar>
          <Column type="buttons">
            <Gridbtn name="edit" />
            <Gridbtn name="delete" />
          </Column>
          <Column dataField="id" allowEditing={false} />
          <Column dataField="name" editorType="dxTextBox">
            <RequiredRule />
          </Column>
          <Column dataField="isUse" width={90} dataType="boolean" />
          <Column
            dataField="startDate"
            width={180}
            dataType="datetime"
            editorType="dxDateBox"
            format="yyyy-MM-dd HH:mm"
          >
            <RequiredRule />
          </Column>
          <Column dataField="isForever" width={90} dataType="boolean" setCellValue={setCellValueIsForever} />
          <Column dataField="endDate" width={180} dataType="datetime" format="yyyy-MM-dd HH:mm" />
          <Column dataField="cronExpression" width={200}>
            <Button text="add new job" type="normal" onClick={onAddClick} />
            <RequiredRule />
          </Column>
          <Summary>
            <TotalItem column="id" summaryType="count" customizeText={customizeTotalCount} alignment="left" />
          </Summary>
        </DataGrid>
      </GridContainer>
    </>
  );
};

export default ScheduleList;
