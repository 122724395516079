import * as React from 'react';
import { Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Draggable } from 'react-beautiful-dnd';


const StyledTab = styled((props: MuiTabProps) => <MuiTab disableRipple {...props} />)<MuiTabProps>(({ theme }) => ({
  paddingRight: '8px',
  textTransform: 'none',
  overflow: 'initial',
  whiteSpace: 'nowrap',
  minHeight: 'auto',
  color: theme.colors.alpha.white[70],
  '&.Mui-selected': {
    color: '#000000',
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    '& .MuiTab-iconWrapper:hover': {
      backgroundColor: theme.colors.alpha.black[10],
    }
  },
}));

const Tab = (props: any) => {
  const { id, index } = props;

  return (
    <Draggable
      draggableId={id}
      index={index}
      disableInteractiveElementBlocking
    >
      {(draggableProvided) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
        >
          <StyledTab {...props} {...draggableProvided.dragHandleProps} {...{style: { cursor: "inherit" }}} />
        </div>
      )}
    </Draggable>
  )
}

export default Tab;