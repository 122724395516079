import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useRecoilValue } from 'recoil';
import { sidebarOpend } from '../../atoms/layout';
import HeaderMenu from './HeaderMenu';

const drawerWidth = 240;
const collapseDrawerWidth = 56;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  background: theme.colors.gradients.header,
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: collapseDrawerWidth,
    width: `calc(100% - ${collapseDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

const Appbar = () => {
  const open = useRecoilValue(sidebarOpend);

  return (
    <AppBar position="fixed" open={open}>
      <HeaderMenu />
    </AppBar>
  );
};

export default Appbar;
