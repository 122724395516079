
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Menu, MenuItem, } from '@mui/material';
import { styled } from '@mui/material/styles';

import { selectedIndexWithStorage, tabsWithStorage, ITabTypes, latestTabsStorage } from '../../atoms/tab';

export interface IContextMenuAttribute {
  mouseX: number;
  mouseY: number;
  id: string;
}

interface ContextMenuProps {
  attribute: IContextMenuAttribute | null;
  contextClose: () => void;
  closeTab: (targetKey: string) => void;
}

const StyledMenu = styled(Menu)(() => ({
  backgroundColor: 'transparent',
  backdropFilter: 'blur(0)',
  '& .MuiMenu-list': {
    padding: '4px 0',
  },
  fontSize: 13,
}))

const paperStyles = {
  minWidth: 160,
  borderRadius: 0,
  boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px 0px, rgba(0, 0, 0, 0.3) 7px 7px 5px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px 0px inset',
}

const ContextMenu = ({ attribute, contextClose, closeTab }: ContextMenuProps) => {
  if (attribute == null) return null;
  const [selectedIndex, setSelectedIndex] = useRecoilState(selectedIndexWithStorage);
  const [tabs, setTabs] = useRecoilState(tabsWithStorage);
  const setLatestTabs = useSetRecoilState(latestTabsStorage);

  const handleSelfClose = () => {
    closeTab(attribute.id)
    contextClose();
  }

  const handleAllClose = () => {
    setTabs([]);
    setSelectedIndex(-1);
    contextClose();
    setLatestTabs({});
  }

  const handleOtherClose = () => {
    setTabs(prv => prv.filter((tab: ITabTypes) => tab.id === attribute.id));
    setSelectedIndex(0);
    contextClose();
  }

  const handleRightClose = () => {
    const targetIndex = tabs.findIndex((tab: ITabTypes) => tab.id === attribute.id);

    if (targetIndex < selectedIndex) {
      setSelectedIndex(targetIndex);
    }
    setTabs(prv => prv.slice(0, targetIndex + 1));
    contextClose();
  }

  return (
    <StyledMenu
      open={attribute !== null}
      anchorReference="anchorPosition"
      anchorPosition={
        attribute !== null
          ? { top: attribute.mouseY, left: attribute.mouseX }
          : undefined
      }
      PaperProps={{ style: paperStyles }}
      onClose={contextClose}
    >
      <MenuItem sx={{ fontSize: 'inherit' }} onClick={handleSelfClose}>닫기</MenuItem>
      <MenuItem sx={{ fontSize: 'inherit' }} onClick={handleAllClose}>모두 닫기</MenuItem>
      <MenuItem sx={{ fontSize: 'inherit' }} onClick={handleOtherClose}>다른 탭 닫기</MenuItem>
      <MenuItem sx={{ fontSize: 'inherit' }} onClick={handleRightClose}>우측 탭 닫기</MenuItem>
    </StyledMenu>
  )
}

export default ContextMenu;