import { IconButton, Box, BoxProps, IconButtonProps } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { useSetRecoilState } from 'recoil';
import { styled } from '@mui/material/styles';
import { sidebarOpend } from '../../atoms/layout';

const IconButtonContainer = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(1, 0, 0, `calc(${theme.spacing(1)} + 2px)`),
  '&:hover': {
    color: theme.palette.warning.dark,
  },
}));

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  '&:hover': {
    color: theme.palette.warning.dark,
    
  },
}));

const ToggleButton = () => {
  const setOpen = useSetRecoilState(sidebarOpend);
  const handleDrawerOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <IconButtonContainer>
      <StyledIconButton disableRipple onClick={handleDrawerOpen}>
        <Menu />
      </StyledIconButton>
    </IconButtonContainer>
  );
};

export default ToggleButton;
