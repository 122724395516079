import { Grid, IconButton, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSetRecoilState } from 'recoil';
import { selectedIndexWithStorage } from 'atoms/tab';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.colors.alpha.white[100],
  '&:hover': {
    color: theme.colors.alpha.white[30],
  },
}));

const AppLauncher = () => {
  const theme = useTheme();
  const setSelectedIndex = useSetRecoilState(selectedIndexWithStorage);

  const handleLogoClick = () => {
    setSelectedIndex(0);
  };

  return (
    <StyledIconButton disableRipple sx={{ p: 0 }} onClick={handleLogoClick}>
      <Grid container sx={{ flexWrap: 'nowrap', alignItems: 'center' }}>
        SUPADUPA
      </Grid>
    </StyledIconButton>
  );
};

export default AppLauncher;
