import React from 'react';
import AutorenewTwoToneIcon from '@mui/icons-material/AutorenewTwoTone';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { styled, keyframes } from '@mui/material/styles';

const rotation = keyframes`
  from{
    transform: rotate(0deg)
  }
  to{
    transform: rotate(360deg)
  }
`;
const LoopIcon = styled(AutorenewTwoToneIcon)({
  animation: `${rotation} 1s linear infinite`,
});

const ChangeCell = (cell) => {
  const { status } = cell.data;
  if (status === 'Running') return <LoopIcon sx={{ color: 'blue' }} />;
  if (status === 'Failed') return <CancelTwoToneIcon sx={{ color: 'red' }} />;
  if (status === 'Completed') return <TaskAltIcon sx={{ color: 'green' }} />;
  return <MoreHorizTwoToneIcon sx={{ color: 'gray' }} />;
};

export default ChangeCell;
