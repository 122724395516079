import React, { useEffect, useState } from 'react';
import CalHeatMap from 'cal-heatmap';
import 'cal-heatmap/cal-heatmap.css';
import Tooltip from 'cal-heatmap/plugins/Tooltip';
import { api } from 'lib/api/JobApi';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import Progress from 'component/Progress';

const LoadStyled = styled('div')(() => ({
  height: 300,
  boxShadow: '2px 10px 20px rgba(0, 0, 0, 0.1)',
  borderRadius: '5px',
  border: '1px solid gray',
  textAlign: 'center',
  padding: '20px',
}));

const HeatMap = ({ id }) => {
  const heatmapID = `heatmap-${id}`;
  const cal = new CalHeatMap();
  const endDt = dayjs(new Date()).format('YYYY-MM-DD'); // 오늘
  const startDt = dayjs().subtract(1, 'year').format('YYYY-MM-DD'); // 일년 전
  const [loadPanelVisible, setLoadPanelVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const position = {
    at: 'center',
    my: 'center',
    of: '#heatDiv',
    // of: `#${heatmapID}`,
  };

  function paint(calSource) {
    cal.paint(
      {
        data: {
          source: calSource,
          x: 'date',
          y: (v) => +v.count,
        },
        date: {
          start: startDt,
        },
        range: 12, // 표시할 도메인 수 지정
        scale: {
          color: {
            type: 'threshold',
            range: ['#81c784', '#43a047', '#1b5e20'],
            domain: [100, 1000],
          },
        },
        domain: {
          type: 'month', // 시간 단위를 나타내는 도메인 유형
          gutter: 10, // 각 도메인 사이의 공간(픽셀 단위)
          label: { text: 'YYYY-MM', position: 'top', textAlign: 'middle' }, // 도메인 레이블과 관련된 모든 옵션 지정
        },
        subDomain: { type: 'day', radius: 2, width: 11, height: 11, gutter: 4 },
        itemSelector: `#${heatmapID}`, // 달력을 렌더링할 위치 지정
      },
      [
        [
          Tooltip,
          {
            text(timestamp, value, dayjsDate) {
              return `${dayjs(dayjsDate).format('YYYY-MM-DD')} count: ${
                value ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '0'
              } `;
            },
          },
        ],
      ],
    );
  }

  function hideLoadPanel() {
    setTimeout(() => {
      setLoadPanelVisible(false);
    }, 8000);
  }

  useEffect(() => {
    const calData = async () => {
      setLoading(true);
      try {
        const res = await api.getJobRunsHistory(id, startDt, endDt);
        const dates = res.map((item) => dayjs(item.startDate).format('YYYY-MM-DD'));
        const counts = dates.reduce((acc, date) => {
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {});
        const calenderData = Object.entries(counts).map(([date, count]) => ({ date, count }));
        console.log(calenderData);
        setLoadPanelVisible(false);
        paint(calenderData);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    calData();
  }, [id]);

  if (loading) {
    return (
      <LoadStyled>
        <Progress />
      </LoadStyled>
    );
  }

  return (
    <div id="heatDiv" style={{ height: 150 }}>
      <div id={heatmapID} />
      {/* <LoadPanel
        visible={loadPanelVisible}
        onHiding={hideLoadPanel()}
        position={position}
      /> */}
    </div>
  );
};

export default HeatMap;
