import React from 'react';
import { Box } from '@mui/material';
import Form, { Item } from 'devextreme-react/form';
import { Editing } from 'devextreme-react/data-grid';
import ScheduleList from './ScheduleList';

const ScheduleListWithProps = (jobId) => <ScheduleList jobId={jobId} />;

const Schedule = ({ data }) => {
  // console.log(data);
  const jobId = data.data.id;

  const add = {
    text: '신규 schedule 추가',
    type: 'default',
    useSubmitBehavior: true,
  };

  const del = {
    text: '삭제',
    type: 'danger',
    useSubmitBehavior: true,
  };

  return (
    <Box style={{ margin: 30 }}>
      <Form colCount={2} formData={data.data} readOnly>
        <Item dataField="id" ditorType="dxTextBox" label={{ text: "JobId" }}/>
        <Item dataField="name" ditorType="dxTextBox" label={{ text: "JobName" }}/>
        {/* <ButtonItem horizontalAlignment="left" buttonOptions={add} /> */}
        <Editing mode="row" allowAdding confirmDelete/>
        {/* <ButtonItem horizontalAlignment="right" buttonOptions={del} colSpan={2} /> */}
        {/* <Item colSpan={2}>Schedule 목록</Item> */}
        <Item component={() => ScheduleListWithProps(jobId)} colSpan={2} />
      </Form>
    </Box>
  );
};

export default Schedule;
