import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Divider } from '@mui/material';
import { useRecoilValue } from 'recoil';
import MenuItemsList from '../Sidebar/MenuItemsList';
import { MENU_ITEMS } from '../Sidebar/MenuItems';
import Logo from '../Logo';
import { sidebarOpend } from '../../atoms/layout';
import ToggleButton from './ToggleButton';
import AppLauncher from '../AppLauncher';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  border: '0px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  width: drawerWidth,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  background: theme.colors.gradients.drawerHeader,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: '48px',
  [theme.breakpoints.up('xs')]: {
    minHeight: '48px',
  },
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      overflow: 'visible',
    },
  }),
}));

const Sidebar = () => {
  const isOpened = useRecoilValue(sidebarOpend);

  return (
    <aside>
      <Drawer variant="permanent" open={isOpened}>
        <DrawerHeader>
          <Logo />
        </DrawerHeader>
        <AppLauncher />
        <Divider />
        <ToggleButton />
        <MenuItemsList options={MENU_ITEMS} />
      </Drawer>
    </aside>
  );
};

export default Sidebar;
