
import { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemText, ListItemTextProps, useTheme, MenuItem, Box, BoxProps } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { MenuItem as MenuItemType } from './MenuItems';
import { sidebarOpend } from '../../atoms/layout';
import { ITabTypes, latestTabsState, latestTabsStorage, selectedIndexWithStorage, tabsWithStorage } from '../../atoms/tab';


const SubItemList = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  '.subItemIconWrapper': {
    display: 'none',
  },
  '&: hover': {
    backgroundColor: theme.colors.secondary.main,
    color: theme.colors.alpha.white[100],
    '.subItemIconWrapper': {
      display: 'flex',
    },
  },
}))

interface SubItemListProps extends BoxProps {
  theme?: any;
  sidebarOpen: boolean;
}
const SubItemListTitle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'sidebarOpen',
})(({ theme, sidebarOpen }: SubItemListProps) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  transition: 'none',
  paddingLeft: sidebarOpen ? theme.spacing(6) : theme.spacing(2),
  height: theme.spacing(5),
}));

const SubItemListIconWrapper = styled(Box)<BoxProps>(() => ({
  marginRight: '1rem',
  height: '100%',
  display: 'flex',
}));

const StyeldBox = styled(Box)<BoxProps>(({ theme }) => ({
  boxShadow: 'rgb(0 0 0 / 40%) 0px 2px 4px 0px, rgb(0 0 0 / 30%) 7px 7px 5px -3px, rgb(0 0 0 / 20%) 0px -3px 0px 0px inset',
  marginTop: '-36px',
  backgroundColor: theme.palette.background.paper,
  zIndex: 9999,
  display: 'none',
  position: 'absolute',
  minWidth: '160px',
  left: `calc(${theme.spacing(7)})`,
  ':hover': {
    display: 'block',
  }
}))

interface MenuItemsListProps {
  subItems: MenuItemType[] | undefined;
  isOpen: boolean;
}

const MenuItemsSubList = ({ subItems, isOpen }: MenuItemsListProps) => {
  const theme = useTheme();
  const sidebarOpen = useRecoilValue(sidebarOpend);
  const StyledListItemText = styled(ListItemText)<ListItemTextProps>({
    span: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
  });

  const setSelectedIndex = useSetRecoilState(selectedIndexWithStorage);
  const [tabs, setTabs] = useRecoilState(tabsWithStorage);
  const [latestTabs, setLatestTabs] = useRecoilState(latestTabsStorage);

  const handleNavClick = (item: MenuItemType, isUnique: boolean) => {
    if (item.element) {
      if (isUnique) {
        const latestTabsName = latestTabs[item.name];
        const existTabIndex = tabs.findIndex((tab) => tab.id === latestTabsName);
        if (existTabIndex !== -1) {
          setSelectedIndex(existTabIndex);
          return;
        }
      }

      const tabId = isUnique ? item.name : `${item.name}_${Math.random().toString(36).substring(2, 8)}`;
      const newTab: ITabTypes = {
        label: item.name,
        id: tabId,
        element: item.element,
        props: { path: item.path },
      };
      setTabs([...tabs, newTab]);
      setLatestTabs({ ...latestTabs, [newTab.label]: newTab.id });
      setSelectedIndex(tabs.length);
    }
  };

  const SubItemListIcon = useCallback(({ item }) => {
    return (
      <SubItemListIconWrapper title='새탭 열기'
        className='subItemIconWrapper'
        onClick={() => handleNavClick(item, false)}>
        <OpenInNewIcon className='icon' sx={{
          width: '100%',
          '&: hover': {
            color: theme.colors.secondary.light
          },
        }} />
      </SubItemListIconWrapper>
    )
  }, [isOpen])



  if (sidebarOpen) {
    return (
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subItems?.map((item) => {
            return (
              <SubItemList key={item.id}>
                <SubItemListTitle sidebarOpen onClick={() => handleNavClick(item, true)}>
                  <StyledListItemText primary={item.name} />
                </SubItemListTitle>
                <SubItemListIcon item={item} />
              </SubItemList>
            );
          })}
        </List>
      </Collapse >
    );
  }

  return (
    <StyeldBox className='navigation-content'>
      {subItems?.map((item) => {
        return (
          <SubItemList key={item.id}>
            <SubItemListTitle sidebarOpen={false} onClick={() => handleNavClick(item, true)}>
              <StyledListItemText primary={item.name} />
            </SubItemListTitle>
            <SubItemListIcon item={item} />
          </SubItemList>
        );
      })}
    </StyeldBox >
  )
};


export default MenuItemsSubList;
