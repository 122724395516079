import React from 'react';
import ChangeCell from 'component/ChangeCell';
import { DataGrid } from 'devextreme-react';
import { Column, Export, FilterRow, Scrolling, Summary, TotalItem } from 'devextreme-react/data-grid';

const RunChartGrid = ({ dataSource }) => {
  const customizeTotalCount = (data) => {
    return `total Count: ${data.value}`;
  };
  return (
    <DataGrid dataSource={dataSource} showBorders showRowLines height={550} columnAutoWidth>
      <Scrolling mode="virtual" />
      <FilterRow visible />
      <Export enabled />
      <Column dataField="jobId" caption="jobId" />
      <Column dataField="jobName" caption="jobName" />
      <Column dataField="runId" caption="runId" alignment="left" />
      <Column dataField="duration" caption="duration" />
      <Column dataField="hostId" caption="hostId" />
      <Column dataField="hostName" caption="hostName" />
      <Column dataField="startDate" caption="startDate" alignment="left" />
      <Column dataField="finishDate" caption="finishDate" />
      <Column dataField="status" caption="status" width={80} cellRender={ChangeCell} alignment="center" />
      <Column dataField="output" caption="output" />
      <Column dataField="error" caption="error" />
      <Summary>
        <TotalItem column="jobId" summaryType="count" customizeText={customizeTotalCount} alignment="left" />
      </Summary>
    </DataGrid>
  );
};

export default RunChartGrid;
