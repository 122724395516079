import { Link, ListItemIcon, ListItemText, MenuList } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { AppLauncherItem as AppLauncherItemType } from './AppLauncherItems';

interface AppLauncherItemProps {
  AppLauncherItem: AppLauncherItemType[];
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1, 3),
  color: theme.palette.common.white,
  '&:hover': {
    background: theme.palette.primary.main,
    color: theme.palette.warning.dark,
  },
}));

const AppLauncherItemsList = ({ AppLauncherItem }: AppLauncherItemProps) => {
  return (
    <MenuList>
      {AppLauncherItem.map(({ title, icon: Icon, href, id }: AppLauncherItemType) => (
        <Link
          key={id}
          href={href}
          underline="none"
          target="_blank"
          rel="noreferrer"
          sx={{ color: 'inherit', display: 'flex' }}
        >
          <StyledMenuItem>
            <ListItemIcon sx={{ color: 'inherit' }}>
              <Icon />
            </ListItemIcon>
            <ListItemText>{title}</ListItemText>
          </StyledMenuItem>
        </Link>
      ))}
    </MenuList>
  );
};

export default AppLauncherItemsList;
