import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid, Button, CardActionArea, CardActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { latestTabsStorage, selectedIndexWithStorage, tabsWithStorage } from 'atoms/tab';
import Workflow from '../Workflow/Workflow';
import Runs from '../Runs/jobRun';

const Cards = styled('div')(() => ({
  boxShadow: '2px 10px 20px rgba(0, 0, 0, 0.1)',
  borderRadius: '5px',
  border: '1px solid #b8b8b8',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
  // padding: '10px 10px 10px',
  height: '100%',
  // backgroundColor: 'lightgray',
}));

const DashboardCard = (props) => {
  const { name, value } = props;
  const setSelectedIndex = useSetRecoilState(selectedIndexWithStorage);
  const [tabs, setTabs] = useRecoilState(tabsWithStorage);
  const [latestTabs, setLatestTabs] = useRecoilState(latestTabsStorage);
  const handleNavClick = (isUnique) => {
    let item;
    if (name === 'totalWorkflow' || name === 'noScheduledWorkflow') {
      item = { name: 'Workflow', path: '/', element: Workflow, isUnique: true };
    }
    if (name === 'todaySuccessRun' || name === 'todayFailRun') {
      item = { name: 'Runs', path: '/', element: Runs, isUnique: true };
    }
    if (item.element) {
      if (isUnique) {
        const latestTabsName = latestTabs[item.name];
        const existTabIndex = tabs.findIndex((tab) => tab.id === latestTabsName);
        if (existTabIndex !== -1) {
          setSelectedIndex(existTabIndex);
          return;
        }
      }
      const tabId = isUnique ? item.name : `${item.name}_${Math.random().toString(36).substring(2, 8)}`;
      const newTab = { label: item.name, id: tabId, element: item.element, props: { path: item.path } };
      setTabs([...tabs, newTab]);
      setLatestTabs({ ...latestTabs, [newTab.label]: newTab.id });
      setSelectedIndex(tabs.length);
    }
  };
  return (
    <Grid item xs={6} md={5} lg={3}>
      <Cards>
        <Card /* variant="outlined" */ p={5}>
          <CardActionArea>
            <CardContent onClick={() => handleNavClick(true)}>
              <Typography gutterBottom fontSize={23} component="div" color="">
                {name}
              </Typography>
              <Typography color="darkgreen" fontSize={30} align="center">
                {value}
              </Typography>
            </CardContent>
          </CardActionArea>
          {/* <CardActions>     <Button size="large" color="inherit">      more     </Button>    </CardActions> */}
        </Card>
      </Cards>
    </Grid>
  );
};
export default DashboardCard;
