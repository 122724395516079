import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import DataGrid, { Column, Summary, TotalItem, Scrolling, FilterRow } from 'devextreme-react/data-grid';
import { api } from 'lib/api/JobApi';
import Button from 'devextreme-react/button';
import dayjs from 'dayjs';
import { Box, Grid } from '@mui/material';
import Calender from 'pages/Dashboard/Calender';
import CustomStore from 'devextreme/data/custom_store';
import DashboardCard from './DashboardCard';
import RunsChart from '../Runs/RunsChart';

const store = new CustomStore({
  key: 'jobId',
  load: () => {
    return api.getActiveRuns();
  },
});

const Dashboard = () => {
  const [cardArr, setCardArr] = useState([]);
  const [todayRuns, setTodayRuns] = useState([]);
  const [refresh, setRefresh] = useState(false);

  // 새로 고침
  const refreshDataGrid = () => {
    setRefresh((prev) => !prev);
    store.load();
  };

  const customizeTotalCount = (data) => {
    return `total Count: ${data.value}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.getCard();
      const data = Object.entries(res).map((card) => ({ name: card[0], value: card[1] }));
      setCardArr(data);
    };

    const todayRunData = async () => {
      const res = await api.getTodayRuns(dayjs().format('YYYY-MM-DD'));
      // const res = await api.getTodayRuns('2023-02-11');
      const seriesDatas = [];

      // #region 차트 데이터 메인 차트 Series 가공
      res.forEach((resData) => {
        let isDuplicate = false;
        seriesDatas.forEach((seriesData) => {
          if (resData.jobId === seriesData.value) isDuplicate = true;
        });
        if (!isDuplicate) seriesDatas.push({ value: resData.jobId, name: resData.jobName });
      });

      // 메인 차트 Data 가공
      const chartDatas = res
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
        .map((item) => {
          const { startDate, duration, jobId } = item;
          return {
            time: dayjs(startDate).format('YYYY-MM-DD HH:mm'),
            [jobId]: new Date(`0001-01-01 ${duration}`),
          };
        });
      setTodayRuns({ seriesDatas, chartDatas });
      // #endregion
    };
    fetchData();
    todayRunData();
  }, [refresh]);

  return (
    <Grid container spacing={1} sx={{ overflowY: 'hidden' }}>
      <Grid item xs={12} m={3}>
        <Button
          icon="refresh"
          text="새로고침"
          type="normal"
          onClick={refreshDataGrid}
          style={{ marginBottom: '20px' }}
        />
        <Grid
          container
          spacing={4}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {cardArr.map((cardData) => (
            <DashboardCard key={cardData.name} name={cardData.name} value={cardData.value} />
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} m={3}>
        <Calender />
      </Grid>

      <Grid item xs={12} style={{ flexWrap: 'wrap' }} m={3}>
        <span style={{ fontSize: '18px' }}> Running Job </span>
        <Box>
          <DataGrid dataSource={store} showBorders rowAlternationEnabled height={350} columnAutoWidth>
            {/* <Scrolling mode="virtual" /> */}
            <FilterRow visible />
            <Column dataField="jobId" caption="jobId" />
            <Column dataField="jobName" caption="jobName" />
            <Column dataField="runId" caption="runId" alignment="left" />
            <Column dataField="duration" caption="duration" />
            <Column dataField="startDate" caption="startDate" />
            <Column dataField="hostId" caption="hostId" />
            <Column dataField="hostName" caption="hostName" />
            <Column dataField="error" caption="error" />
            <Column dataField="output" caption="output" />
            <Summary>
              <TotalItem column="jobId" summaryType="count" customizeText={customizeTotalCount} alignment="left" />
            </Summary>
          </DataGrid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box m={3}>
          <span style={{ fontSize: '18px' }}>Today Runs</span>
          <RunsChart dataSource={todayRuns} />
        </Box>
      </Grid>
    </Grid>
  );
};
export default Dashboard;
