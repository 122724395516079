import * as signalR from '@microsoft/signalr';

export const connection = new signalR.HubConnectionBuilder()
  .withUrl('https://supadupa-scheduler-dev.nicemeadow-ed13bb66.koreacentral.azurecontainerapps.io/JobState')
  .configureLogging(signalR.LogLevel.Information)
  .build();

const signal = () => {
  async function start() {
    try {
      await connection.start();
      console.log('SignalR Connected.');
    } catch (err) {
      console.log(err);
      setTimeout(start, 5000);
    }
  }
  connection.start();
};

export default signal;
