import React, { useRef } from 'react';
import Scheduler, { Resource } from 'devextreme-react/scheduler';
import { SimpleData, assignees } from './SimpleData';

const Calender = () => {
  // const ScheduleRef = useRef();
  // console.log(ScheduleRef.current.instance);
  const currentDate = new Date();
  const views = ['day', 'week', /*  'workWeek', */ 'month'];

  return (
    <Scheduler
      dataSource={SimpleData}
      views={views}
      height={700}
      maxAppointmentsPerCell={3}
      // ref={ScheduleRef}
      defaultCurrentDate={currentDate}
      defaultCurrentView="week" // 페이지 처음 켰을때 기본탭
      // startDayHour={9}
    >
      <Resource dataSource={assignees} fieldExpr="assigneeId" label="assignee" useColorAsDefault />
    </Scheduler>
  );
};

export default Calender;
