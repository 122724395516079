import React, { useState, useRef, useEffect } from 'react';
import Button from 'devextreme-react/button';
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  CommonSeriesSettings,
  Margin,
  Tooltip,
  Point,
  Label,
  Legend,
} from 'devextreme-react/chart';
import RangeSelector, {
  Size,
  Scale,
  Chart as RsChart,
  CommonSeriesSettings as RsCommonSeriesSettings,
  Series as RsSeries,
  Margin as RsMargin,
  Behavior,
} from 'devextreme-react/range-selector';
import { useRecoilValue } from 'recoil';
import { selectedIndexState } from '../../atoms/tab';

const RunsChart = ({ dataSource }) => {
  const { seriesDatas, chartDatas } = dataSource;

  const selectedIndexTab = useRecoilValue(selectedIndexState);
  const chartRef = useRef();
  const rangeRef = useRef();
  const [visualRange, setVisualRange] = useState({ visualRange: { startValue: '', endValue: '' } });

  const updateVisualRange = (e) => {
    setVisualRange({ visualRange: e.value });
  };

  const customizeText = ({ value }) => {
    return `${value.getHours().toString().padStart(2, 0)}:${value.getMinutes().toString().padStart(2, 0)}:${value
      .getSeconds()
      .toString()
      .padStart(2, 0)}`;
  };

  const exportChart = () => {
    chartRef.current.instance.exportTo('Chart', 'png');
  };

  useEffect(() => {
    const chartInstance = chartRef.current.instance;
    // const rangeInstance = rangeRef.current.instance;
    // rangeInstance.render();
    chartInstance.render();
  }, [selectedIndexTab]);

  return (
    <>
      <div>
        <Button icon="export" onClick={exportChart} style={{ float: 'right', marginBottom: '10px' }} />
      </div>
      <Chart ref={chartRef} palette="Ocean" dataSource={chartDatas} height={500}>
        <CommonSeriesSettings argumentField="time" type="spline">
          <Point size={7} />
        </CommonSeriesSettings>

        {seriesDatas?.map((item) => (
          <Series key={item.value} valueField={item.value} name={item.name} axis="dateTime" />
        ))}

        <ArgumentAxis visualRange={visualRange.visualRange} />
        <ValueAxis name="dateTime" position="left" type="dateTime" axisDivisionFactor={90}>
          <Label customizeText={customizeText} />
        </ValueAxis>

        <Legend verticalAlignment="bottom" horizontalAlignment="center" />
        <Margin bottom={20} />
        <Tooltip enabled format="HH:mm:ss:SSS" />
      </Chart>

      {/* 차트 범위선택  */}
      <RangeSelector /* ref={rangeRef} */ dataSource={chartDatas} onValueChanged={updateVisualRange}>
        <Size height={150} />
        <RsMargin top={10} />
        <Scale startValue={visualRange.visualRange.startValue} endValue={visualRange.visualRange.endValue} />
        <Behavior callValueChanged="onMoving" />
        <RsChart palette="Ocean">
          <RsCommonSeriesSettings argumentField="time" type="spline" />
          {seriesDatas?.map((item) => (
            <RsSeries key={item.value} valueField={item.value} name={item.name} />
          ))}
        </RsChart>
      </RangeSelector>
    </>
  );
};

export default RunsChart;
