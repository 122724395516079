import { useRecoilState } from 'recoil';
import { ReactNode } from 'react';
import { tabsState, selectedIndexState } from '../atoms/tab';
import BasicPage from '../component/Page';

interface TabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
}
const TabPanel = ({ children, value, index }: TabPanelProps) => {
  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {children}
    </div>
  );
};

const Home = () => {
  const [tabs] = useRecoilState(tabsState);
  const [selectedIndex] = useRecoilState(selectedIndexState);

  return (
    <div style={{ width: '100%' }}>
      {tabs.map((tab, i) => (
        <TabPanel key={tab.id} value={selectedIndex} index={i}>
          {/* <tab.element {...tab.props} key={tab.id} /> */}
            <tab.element {...tab.props} />
        </TabPanel>
      ))}
    </div>
  );
};

export default Home;
