import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import CustomStore from 'devextreme/data/custom_store';
import { DateBox, DropDownBox, TreeView } from 'devextreme-react';
import dayjs from 'dayjs';
import Button from 'devextreme-react/button';
import { api } from 'lib/api/JobApi';
import RunsChart from './RunsChart';
import RunChartGrid from './RunChartGrid';

const JobRun = () => {
  const [startDt, setStartDt] = useState(dayjs().add('-1', 'day').format('YYYY-MM-DD HH:mm'));
  const [endDt, setEndDt] = useState(dayjs().format('YYYY-MM-DD HH:mm'));
  const [runData, setRunData] = useState([]);
  const [selectIds, setSelectIds] = useState([]);
  const [chartGrid, setChartGrid] = useState();

  const [refresh, setRefresh] = useState(false);
  const treeViewRef = useRef(null);

  const syncSelection = (treeView) => {
    const selectedEmployees = treeView.getSelectedNodes().map((node) => node.itemData.id);

    setSelectIds(selectedEmployees);
  };
  const store = useMemo(() => {
    return new CustomStore({
      key: 'id',
      load: () => {
        return api.getJobList(0, 1000);
      },
    });
  }, [refresh, startDt, endDt]);

  // 새로 고침
  const refreshDataGrid = () => {
    setRefresh((prev) => !prev);
    store.load();
  };
  useEffect(() => {
    if (selectIds.length === 0) {
      setChartGrid([]);
      setRunData([]);
    } else {
      api.getJobRuns(selectIds, startDt, endDt).then((res) => {
        setChartGrid(res);
        const seriesDatas = [];
        // #region 차트 데이터 메인 차트 Series 가공
        res.forEach((resData) => {
          let isDuplicate = false;
          seriesDatas.forEach((seriesData) => {
            if (resData.jobId === seriesData.value) isDuplicate = true;
          });
          if (!isDuplicate) seriesDatas.push({ value: resData.jobId, name: resData.jobName });
        });

        // 메인 차트 Data 가공
        const chartDatas = res
          .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
          .map((item) => {
            const { startDate, duration, jobId } = item;
            return {
              time: dayjs(startDate).format('YYYY-MM-DD HH:mm'),
              [jobId]: new Date(`0001-01-01 ${duration}`),
            };
          });

        setRunData({ seriesDatas, chartDatas });
      });
    }
  }, [startDt, endDt, selectIds, refresh]);

  const selectProduct = (e) => {
    setSelectIds(e.component.getSelectedNodesKeys());
    // console.log(e.component.getSelectedNodesKeys());
  };

  const treeViewSelectionChanged = (e) => {
    syncSelection(e.component);
  };

  const onContentReady = (e) => {
    e.component?.selectAll();
  };

  const treeViewRender = () => {
    return (
      <div>
        <TreeView
          id="treeview"
          ref={treeViewRef}
          height={300}
          dataSource={store}
          dataStructure="plain"
          selectionMode="multiple"
          showCheckBoxesMode="selectAll"
          selectNodesRecursive={false}
          displayExpr="name"
          selectByClick
          onContentReady={onContentReady}
          // onInitialized={onInitialized} // 트리뷰 초기화가 완료되면 호출되는 이벤트 핸들러
          onItemSelectionChanged={selectProduct} // 단일 TreeView 항목을 선택하거나 선택을 취소할 때 실행되는 기능
          onSelectionChanged={treeViewSelectionChanged}
        />
      </div>
    );
  };
  return (
    <Box style={{ margin: 30 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button icon="refresh" text="새로고침" type="normal" onClick={refreshDataGrid} />
        <span style={{ padding: '10px' }}>Job StartDt </span>
        <DateBox
          value={startDt}
          type="datetime"
          displayFormat="yyyy-MM-dd HH:mm"
          style={{ margin: '10px' }}
          onValueChanged={(e) => {
            const dateTimeFormat = dayjs(e.value).format('YYYY-MM-DD HH:mm');
            setStartDt(dateTimeFormat);
          }}
        />
        ~
        <DateBox
          value={endDt}
          type="datetime"
          displayFormat="yyyy-MM-dd HH:mm"
          style={{ margin: '10px' }}
          onValueChanged={(e) => {
            const endDateFormat = dayjs(e.value).format('YYYY-MM-DD HH:mm');
            setEndDt(endDateFormat);
          }}
        />
        <span style={{ padding: '10px' }}>Select Jobs</span>
        <DropDownBox
          width={300}
          valueExpr="id"
          placeholder="Select job ..."
          showClearButton
          contentRender={treeViewRender}
        />
      </div>
      <RunsChart dataSource={runData} />

      <div style={{ marginTop: '30px' }}>
        <RunChartGrid dataSource={chartGrid} />
      </div>
    </Box>
  );
};

export default JobRun;
