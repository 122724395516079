export const SimpleData = [
  {
    text: 'Website Re-Design Plan',
    assigneeId: 1,
    startDate: new Date('2023-04-26T16:30:00.000Z'),
    endDate: new Date('2023-04-26T18:30:00.000Z'),
  },
  {
    text: 'Book Flights to San Fran for Sales Trip',
    assigneeId: 1,
    startDate: new Date('2023-04-26T19:00:00.000Z'),
    endDate: new Date('2023-04-26T20:00:00.000Z'),
    allDay: true,
  },
  {
    text: 'Install New Router in Dev Room',
    assigneeId: 1,
    startDate: new Date('2023-04-26T21:30:00.000Z'),
    endDate: new Date('2023-04-26T22:30:00.000Z'),
  },
  {
    text: 'Approve Personal Computer Upgrade Plan',
    assigneeId: 2,
    startDate: new Date('2023-04-27T17:00:00.000Z'),
    endDate: new Date('2023-04-27T18:00:00.000Z'),
  },
  {
    text: 'Final Budget Review',
    assigneeId: 4,
    startDate: new Date('2023-04-27T19:00:00.000Z'),
    endDate: new Date('2023-04-27T20:35:00.000Z'),
  },
  {
    text: 'New Brochures',
    startDate: new Date('2023-04-27T21:30:00.000Z'),
    endDate: new Date('2023-04-27T22:45:00.000Z'),
  },
  {
    text: 'Install New Database',
    assigneeId: 4,
    startDate: new Date('2023-05-28T16:45:00.000Z'),
    endDate: new Date('2023-05-28T18:15:00.000Z'),
  },
  {
    text: 'Final Budget Review',
    assigneeId: 4,
    startDate: new Date('2023-05-27T19:00:00.000Z'),
    endDate: new Date('2023-05-27T20:35:00.000Z'),
  },
  {
    text: 'New Brochures',
    assigneeId: 4,
    startDate: new Date('2023-05-27T21:30:00.000Z'),
    endDate: new Date('2023-05-27T22:45:00.000Z'),
  },
  {
    text: 'Install New Database',
    assigneeId: 3,
    startDate: new Date('2023-05-28T16:45:00.000Z'),
    endDate: new Date('2023-05-28T18:15:00.000Z'),
  },
  {
    text: 'Approve New Online Marketing Strategy',
    assigneeId: 1,
    startDate: new Date('2023-05-28T19:00:00.000Z'),
    endDate: new Date('2023-05-28T21:00:00.000Z'),
  },
  {
    text: 'Upgrade Personal Computers',
    assigneeId: 1,
    startDate: new Date('2023-05-28T22:15:00.000Z'),
    endDate: new Date('2023-05-28T23:30:00.000Z'),
  },
  {
    text: 'Customer Workshop',
    assigneeId: 1,
    startDate: new Date('2023-05-29T18:00:00.000Z'),
    endDate: new Date('2023-05-29T19:00:00.000Z'),
    allDay: true,
  },
  {
    text: 'Prepare 2023 Marketing Plan',
    assigneeId: 2,
    startDate: new Date('2023-05-29T18:00:00.000Z'),
    endDate: new Date('2023-05-29T20:30:00.000Z'),
  },
  {
    text: 'Brochure Design Review',
    assigneeId: 1,
    startDate: new Date('2023-05-29T21:00:00.000Z'),
    endDate: new Date('2023-05-29T22:30:00.000Z'),
  },
  {
    text: 'Create Icons for Website',
    assigneeId: 2,
    startDate: new Date('2023-04-30T17:00:00.000Z'),
    endDate: new Date('2023-04-30T18:30:00.000Z'),
  },
  {
    text: 'Upgrade Server Hardware',
    assigneeId: 3,
    startDate: new Date('2023-04-30T21:30:00.000Z'),
    endDate: new Date('2023-04-30T23:00:00.000Z'),
  },
  {
    text: 'Brochure Design Review',
    assigneeId: 3,
    startDate: new Date('2023-04-29T21:00:00.000Z'),
    endDate: new Date('2023-04-29T22:30:00.000Z'),
  },
  {
    text: 'Create Icons for Website',
    assigneeId: 1,
    startDate: new Date('2023-04-30T17:00:00.000Z'),
    endDate: new Date('2023-04-30T18:30:00.000Z'),
  },
  {
    text: 'Create Icons for Website',
    assigneeId: 1,
    startDate: new Date('2023-04-30T17:00:00.000Z'),
    endDate: new Date('2023-04-30T18:30:00.000Z'),
  },
  {
    text: 'Create Icons for Website',
    assigneeId: 3,
    startDate: new Date('2023-04-30T17:00:00.000Z'),
    endDate: new Date('2023-04-30T18:30:00.000Z'),
  },
  {
    text: 'Upgrade Server Hardware',
    assigneeId: 2,
    startDate: new Date('2023-04-30T21:30:00.000Z'),
    endDate: new Date('2023-04-30T23:00:00.000Z'),
  },
  {
    text: 'Submit New Website Design',
    assigneeId: 3,
    startDate: new Date('2023-05-30T23:30:00.000Z'),
    endDate: new Date('2023-05-01T01:00:00.000Z'),
  },
  {
    text: 'Launch New Website',
    assigneeId: 1,
    startDate: new Date('2023-04-30T19:20:00.000Z'),
    endDate: new Date('2023-04-30T21:00:00.000Z'),
  },
];

export const assignees = [
  {
    text: '1',
    id: 1,
    color: '#727bd2',
  },
  {
    text: '2',
    id: 2,
    color: '#32c9ed',
  },
  {
    text: '3',
    id: 3,
    color: '#2a7ee4',
  },
  {
    text: '4',
    id: 4,
    color: '#7b49d3',
  },
];
