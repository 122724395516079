import axios from 'axios';
import keycloak from 'lib/keycloak';
import {
  UserType,
  UpdateUserType,
  GroupType,
  CreateGroupType,
  UpdateGroupType,
  DeleteGroupType,
  UserCredentialsType,
  UserGroupsType,
  UserSessionsType,
  UserIDProviderLinksType,
  RoleType,
  UserRolesType,
  InserUserRolesType,
  UpdateUserPasswordType,
  RemoveUserGroupType,
  InsertUserGroupType,
  AuthType
} from './type';


// const userPageId = useRecoilValue(userId)
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
});


async function getReq<T>(api: string): Promise<T> {
  // const res = await API.get<T>(api, params);
  const res = await API({
    method: 'get',
    url: api,
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    }
  })
  return res.data;
}

async function getListReq<T>(api: string) {
  const res = await API({
    method: 'get',
    url: api,
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    }
  })
  return res.data;
}

async function postReq<T>(api: string, data?: T) {
  // const res = await API.post<T>(api, params);
  const res = await API({
    method: 'post',
    url: api,
    data,
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    }
  })
  return res.data;
}
async function putReq<T>(api: string, data?: T) {
  // const res = await API.put<T>(api, params);
  const res = await API({
    method: 'put',
    url: api,
    data,
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    }
  })
  return res.data;
}
async function deleteReq<T>(api: string) {
  await API({
    method: 'delete',
    url: api,
    headers: {
      Authorization: `Bearer ${keycloak.token}`
    }
  })
  // await API.delete<T>(api, params);
}

export const users = {
  // user
  getUsers: () => getListReq<UserType>(`/users`),
  getUser: (id: string) => getReq<UserType>(`/users/${id}`),
  createUser: (newUser: UpdateUserType) => postReq<UpdateUserType>(`/users`, newUser),
  updateUser: (id: string, updateUser: UpdateUserType) => putReq<UpdateUserType>(`/users/${id}`, updateUser),
  deleteUser: (id: string) => deleteReq(`/users/${id}`),

  // userGroups
  insertUserGroups: (userId: string, groupId: string) => putReq<InsertUserGroupType>(`/users/${userId}/groups/${groupId}`),
  getUserGroups: (id: string) => getListReq<UserGroupsType>(`/users/${id}/groups`),
  removeUserGroups: (userId: string, groupId: string) => deleteReq<RemoveUserGroupType>(`users/${userId}/groups/${groupId}`),
  // userCredentials
  getUserCredentials: (id: string) => getListReq<UserCredentialsType>(`/users/${id}/credentials`),
  updateUserPasswrod: (id: string, updateData: UpdateUserPasswordType) => putReq<UpdateUserPasswordType>(`/users/${id}/reset-password`, updateData),

  // userSessions
  getUserSessions: (id: string) => getListReq<UserSessionsType>(`/users/${id}/sessions`),
  deleteUserSessions: (userId: string, sessionId: string) => deleteReq(`users/${userId}/sessions/${sessionId}`),
  deleteUserAllSessions: (id: string) => postReq(`users/${id}/logout`),

  // use ID PRovider Links
  getUserIDProviderLinks: (id: string) => getListReq<UserIDProviderLinksType>(`/users/${id}/federated-identity`),
  // deleteUserIDProviderLinks: (userId: string, sessionId: string) => deleteReq(`users/${userId}/federated-identity`),

};
export const authority = {
  // 역할
  getRoles: () => getListReq<RoleType>(`/authority/roles`),
  createRoles: (newRole: RoleType) => postReq<RoleType>(`/authority/roles`, newRole),
  updateRoles: (roleId: string, updateRole: RoleType) => putReq<RoleType>(`/authority/roles/${roleId}`, updateRole),
  deleteRoles: (roleId: string) => deleteReq(`/authority/roles/${roleId}`),

  // role auth
  getRoleAuth: (roleId: string) => getListReq<any>(`/authority/roles/${roleId}/auth`),
  // getRoleAuth: (roleId: string) => getListReq<any>(`/authority/roles/${roleId}/auth`),
  // insertRoleAuth: (roleId: string, authId: string) => postReq<RoleAuthType>(`/authority/roles/${roleId}/auth`, { id: authId }),
  // updateRoleAuth: (roleId: string, authId: string, updateRoleAuth: RoleAuthType) => putReq<RoleAuthType>(`/authority/roles/${roleId}/auth/${authId}`, updateRoleAuth),
  // removeRoleAuth: (roleId: string, authId: string) => deleteReq(`/authority/roles/${roleId}/auth/${authId}`),

  // user roles
  getUserRoles: (id: string) => getListReq<UserRolesType>(`/authority/user/${id}`),
  insertUserRoles: (userId: string, roleId: string) => postReq<InserUserRolesType>(`/authority/user/${userId}/roles`, { id: roleId }),
  removeUserRoles: (userId: string, roleId: string) => deleteReq(`/authority/user/${userId}/roles/${roleId}`),

  // 권한
  getAuthList: () => getListReq<AuthType>(`/authority/auth`),
  // getAuth: (authId: string) => getListReq<RoleType>(`/authority/auth/${authId}`),
  updateAuth: (authId: string, updataData: AuthType) => putReq<AuthType>(`/authority/auth/${authId}`, updataData),
  createAuth: (auth: AuthType) => postReq<AuthType>(`/authority/auth`, auth),
  deleteAuth: (authId: string) => deleteReq(`/authority/auth/${authId}`),
  getUserAtiveAuthList: (userId: string) => getListReq(`/authority/user/${userId}/auth`),

};
export const groups = {
  getGroups: () => getListReq<GroupType>(`/groups`),
  getUser: (groupId: string) => getReq<UserType>(`/users?groupid=${groupId}`),
  createGroup: (newGroup: CreateGroupType) => postReq<CreateGroupType>(`/groups`, newGroup),
  updateGroup: (id: string, updateGroup: UpdateGroupType) => putReq<UpdateGroupType>(`/groups/${id}`, updateGroup),
  deleteGroup: (id: string) => deleteReq<DeleteGroupType>(`/groups/${id}`),
};
export const secret = {
  getSecretGroups: () => getReq<any>(`/secret`),
  getSecrets: (groupName: string) => getReq<any>(`/secret/${groupName}`),
  // createSecretGroup: (params: any) => postReq<any>(`/secret`, params),
  createSecretGroup: (params: any) => API.post<any>(`/secret`, params),
  // getSecretGroup: (name: string) => getReq<any>(`/secret/${name}`),
  getSecretGroup: (name: string) => API.get<any>(`/secret/${name}/data`),
  updateSecretGroup: (name: string, params: any) => API.put<any>(`/secret/${name}`, params),
  deleteSecretGroup: (name: string) => API.delete<any>(`/secret/${name}`),
  createSecret: (secretGroupName: string, secretName: string, params: any) => API.post<any>(`/secret/${secretGroupName}/data/${secretName}`, params),
  getSecret: (secretGroupName: string, secretName: string) => API.get<any>(`/secret/${secretGroupName}/data/${secretName}`),
  updateSecret: (secretGroupName: string, secretName: string, params: any) => API.post<any>(`/secret/${secretGroupName}/data/${secretName}`, params),
  deleteSecret: (secretGroupName: string, secretName: string) => API.delete<any>(`/secret/${secretGroupName}/metadata/${secretName}`)
};

export const dashboard = {
  getResourceCount: () => getReq<any>(`/metric/count`),
  getAppCurrentSessionCount: () => getListReq<any>(`/metric/session`),
  getDateAppCurrentUserCount: () => getListReq<any>(`/metric/login/application/date`),
  getLoginTypeFailCount: () => getListReq<any>(`/metric/login/error`),
  getIdProviderCount: () => getListReq<any>(`/metric/idp/count`)
}

export default API;
