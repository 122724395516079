import React, { useState } from 'react';
import CronGenerator from 'react-cron-generator';

const Cron = () => {
  const [cronValue, setCronValue] = useState('');

  const handleCronChange = (cron) => {
    // 크론식이 변경될 때 호출되는 콜백 함수
    console.log(cron);
    setCronValue(cron);
  };

  return (
    <div>
      <CronGenerator onChange={handleCronChange} value={cronValue} showResultText showResultCron />
      <div>
        <strong>현재 크론식: </strong>
        {cronValue}
      </div>
    </div>
  );
};

export default Cron;
