import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { api } from 'lib/api/JobApi';
import dayjs from 'dayjs';
import Chart from './WorkflowChart';

const Run = (props) => {
  const jobId = props.data.data.id;
  const [runData, setRunData] = useState([]);

  useEffect(() => {
    try {
      api.getRecentRun(jobId).then((res) => {
        const data = res.map((item) => {
          const { status, jobName, duration } = item;
          const startDate = dayjs(item.startDate).format('YYYY-MM-DD HH:mm:ss.SSS');
          return {
            duration,
            startDate,
            status,
            jobName,
          };
        });
        setRunData(data);
      });
    } catch (e) {
      console.log(e);
    }
  }, [jobId]);

  return (
    <Box style={{ margin: 30 }}>
      <Chart dataSource={runData} />
    </Box>
  );
};

export default Run;
