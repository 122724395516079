import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'devextreme/dist/css/dx.light.css';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Privacypolicy from 'pages/Policy/Privacypolicy';
import SessionCheckSection from 'lib/keycloak/SessionCheckSection';
import Progress from 'component/Progress';
import keycloak from 'lib/keycloak';
import Layout from 'layouts/Layout';
import API from 'lib/api'
import 'component/override.css';

const eventLogger = (event: unknown) => {
  if (event === 'onTokenExpired') {
    keycloak.logout();
  }
};

const tokenLogger = (tokens: any) => {
  API.defaults.headers.common.Authorization = `Bearer ${tokens.token}`
};

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  const ENV = process.env.REACT_APP_ENV;
  const envTitle = ENV === 'DEV' ? `[${ENV}]` : '';
  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <title>{envTitle}SUPADUPA</title>
      </Helmet>

      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={3000}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/privacypolicy" element={<Privacypolicy />} />
            <Route path="/*" element={
              <ReactKeycloakProvider
                authClient={keycloak}
                onEvent={eventLogger}
                onTokens={tokenLogger}
                initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
                LoadingComponent={<Progress />}
              >
                <Layout />
                <SessionCheckSection />
              </ReactKeycloakProvider>} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
export default App;
