import { atom, selector } from 'recoil';

import {
  getStoredTabs,
  getStoredIndex,
  setIndexStorage,
  setTabsStorage,
  setStorage,
  getStoredLatestTabs,
} from '../lib/tabsStorage';

import Dashboard from '../pages/Dashboard/Dashboard';

export const selectedIndexState = atom<number>({
  key: 'selectedIndexState',
  default: getStoredIndex() || 0,
});

export const selectedIndexWithStorage = selector<number>({
  key: 'selectedIndexWithStorage',
  get: ({ get }) => get(selectedIndexState),
  set: ({ set }, newValue) => {
    set(selectedIndexState, newValue);
    setIndexStorage(newValue as number);
  },
});

export interface ITabTypes {
  label: string;
  id: string;
  element: (props?: any) => JSX.Element;
  props?: any;
}

export type LatestTabsType = Record<string, string>;

const defaultTab = [
  {
    label: 'Dashboard',
    id: 'Dashboard',
    element: Dashboard,
    props: { propTest: null },
  },
];

const defaultLatestTab: LatestTabsType = {
  Workflow: 'Dashboard',
};

export const tabsState = atom<ITabTypes[]>({
  key: 'tabsState',
  default: getStoredTabs() || defaultTab,
});

export const tabsWithStorage = selector<ITabTypes[]>({
  key: 'tabsWithStorage',
  get: ({ get }) => get(tabsState),
  set: ({ set }, newValue) => {
    set(tabsState, newValue);
    setTabsStorage(newValue as ITabTypes[]);
  },
});

export const latestTabsState = atom<LatestTabsType>({
  key: 'latestTabsState',
  default: getStoredLatestTabs() || defaultLatestTab,
});

export const latestTabsStorage = selector<LatestTabsType>({
  key: 'latestTabsStorage',
  get: ({ get }) => get(latestTabsState),
  set: ({ set }, newValue) => {
    set(latestTabsState, newValue);
    setStorage<LatestTabsType>(newValue as LatestTabsType, 'latestTabs');
  },
});
