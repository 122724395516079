import { useState } from 'react';
import {
  Box,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Avatar,
  Typography,
  useTheme,
} from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { useKeycloak } from '@react-keycloak/web';
import PersonIcon from '@mui/icons-material/Person';
import TabsBar from '../TabsBar';

const HeaderMenu = () => {
  const { keycloak } = useKeycloak();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'pc-menu';
  const renderMenu = (
    <Menu
      PaperProps={{
        style: {
          border: `1px solid ${theme.palette.primary.light}`
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        backdropFilter: 'blur(0)',
      }}
    >
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem onClick={() => keycloak.logout()}>Logout</MenuItem>
    </Menu >
  );

  const mobileMenuId = 'mobile-menu';
  const renderMobileMenu = (
    <Menu
      PaperProps={{
        sx: {
          border: `1px solid ${theme.palette.primary.light}`,
          ul: {
            paddingTop: 0,
            paddingBottom: 0,
          }

        }
      }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        backdropFilter: 'blur(0)',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem sx={{ borderBottom: '1px solid ' }}>
        <Avatar sx={{ width: 25, height: 25, bgcolor: theme.palette.primary.dark }}>
          <PersonIcon />
        </Avatar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            color: theme.palette.primary.dark,
            marginLeft: '10px'
          }}
        >
          <Typography>{keycloak.tokenParsed?.preferred_username}</Typography>
          <Typography variant="caption">SD Team</Typography>
        </Box>
      </MenuItem>
      <MenuItem onClick={() => keycloak.logout()}>Logout</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ width: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        <Box sx={{
          width: '1px',
          height: '70%',
          backgroundColor: theme.colors.primary.light,
        }} />
      </Box>
      <TabsBar />
      <Toolbar variant="dense" sx={{
        cursor: 'pointer',
        '&:hover': {
          background: theme.colors.gradients.profileOver
        }
      }}>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {keycloak.authenticated ? (
            <IconButton disableRipple onClick={handleProfileMenuOpen} sx={{ p: 0 }} >
              <Avatar sx={{ width: 36, height: 36, bgcolor: theme.palette.primary.light }}>
                <PersonIcon />
              </Avatar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  color: theme.palette.common.white,
                  ml: 1,
                }}
              >
                <Typography>{keycloak.tokenParsed?.preferred_username}</Typography>
                <Typography variant="caption">SD Team</Typography>
              </Box>
            </IconButton>
          ) : (
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                keycloak.login();
              }}
            >
              로그인을 해주세요.
            </Typography>
          )}
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
            sx={{ padding: theme.spacing(0) }}
          >
            <MoreHoriz />
          </IconButton>
        </Box>
      </Toolbar >
      {renderMobileMenu}
      {renderMenu}
    </Box >
  );
};
export default HeaderMenu;
