import { Chart, Series, ValueAxis, Margin, Tooltip, Label, Legend } from 'devextreme-react/chart';
import Button from 'devextreme-react/button';
import { useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedIndexState } from '../../atoms/tab';

const RunChart = (props) => {
  const selectedIndexTab = useRecoilValue(selectedIndexState);
  const chartRef = useRef();
  // console.log('dataSource', props.dataSource);
  const dataSource = props.dataSource
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    .map((time) => {
      // console.log(time);props.dataSource
      return {
        ...time,
        time: time.duration,
        // timeStampe: new Date(`0001-01-01 ${time.duration}`).getTime(),
        dateTime: new Date(`0001-01-01 ${time.duration}`),
      };
    });

  // console.log('dataSource', dataSource);

  const customizeText = ({ value }) => {
    return `${value.getHours().toString().padStart(2, 0)}:${value.getMinutes().toString().padStart(2, 0)}:${value
      .getSeconds()
      .toString()
      .padStart(2, 0)}`;
  };

  const customizePoint = (arg) => {
    if (arg.data.status === 'Completed' || arg.data.status === 'Running') {
      return { color: '#8c8c', hoverStyle: { color: '#69e058' } };
    }
    if (arg.data.status === 'Failed' || arg.data.status === 'Canceled') {
      return { color: '#ff7c7c', hoverStyle: { color: '#e08158' } };
    }
    return null;
  };

  useEffect(() => {
    const chartInstance = chartRef.current.instance;
    chartInstance.render();
  }, [selectedIndexTab]);

  const exportChart = () => {
    chartRef.current.instance.exportTo('Chart', 'png');
  };
  return (
    <>
      <Button icon="export" onClick={exportChart} style={{ float: 'right', marginBottom: '10px' }} />
      <Chart ref={chartRef} palette="Ocean" dataSource={dataSource} customizePoint={customizePoint}>
        <Series
          valueField="dateTime"
          // valueField="time"
          argumentField="startDate"
          // name={props.dataSource[0]?.jobName}
          type="bar"
        />
        <Legend visible={false} />
        <ValueAxis
          name="dateTime"
          position="left"
          tickInterval={{ seconds: 10 }}
          // axisDivisionFactor={300}
          type="dateTime"
        >
          <Label customizeText={(arg) => customizeText(arg)} />
        </ValueAxis>
        <Margin bottom={20} />
        <Tooltip enabled format="HH:mm:ss:SSS" />
      </Chart>
      <div style={{ paddingBottom: '10px' }}>[최근 10개 차트]</div>
    </>
  );
};

export default RunChart;
