import DataGrid, {
  Column,
  MasterDetail,
  Selection,
  FilterRow,
  Paging,
  Summary,
  TotalItem,
  Toolbar,
  Button as GridButton,
  Item,
  Pager,
} from 'devextreme-react/data-grid';
import { useState, useRef, useEffect } from 'react';
import Button from 'devextreme-react/button';
import CalendarHeatmap from 'react-calendar-heatmap';
import { SnackbarProvider } from 'notistack';
import notify from 'devextreme/ui/notify';
import signal, { connection } from 'lib/signal';
import CustomStore from 'devextreme/data/custom_store';
import ChangeCell from 'component/ChangeCell';
import Cron from 'pages/Policy/Cron';
import { styled } from '@mui/material/styles';
import DataSource from 'devextreme/data/data_source';
import { api } from '../../lib/api/JobApi';
import MasterDetailView from './MasterDetailView';
import JobPopup from '../Policy/JobPopup';

const pageSizes = [20, 35, 50, 100];

const HeatMapStyld = styled('div')(() => ({
  paddingTop: '70px',
  color: 'blueviolet',
}));
// test
const Workflow = () => {
  const [dataSource, setDataSource] = useState();
  const [popupVisible, setPopupVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const formRef = useRef(null);
  const fileRef = useRef(null);
  const checkedIds = useRef();

  useEffect(() => {
    const JobDataSource = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: () => {
          return api.getJobList(0, 1000);
        },
      }),
    });
    setDataSource(JobDataSource.store());
    if (connection.state !== 'Connected') {
      signal();
      connection.on('ReceiveState', (id, state) => {
        JobDataSource.store().push([{ type: 'update', key: id, data: { status: state } }]);
        // setDataSource(JobDataSource.store());
        // console.log('메세지', 'jobId=>', id, 'state=>', state);
      });
    }
    return () => {
      connection.stop();
    };
  }, [refresh]);

  const startJob = async (e) => {
    const { id: jobId, name } = e.row.data;
    await api.startJob(jobId);
    notify({ message: `${name} 작업이 시작되었습니다.`, width: 350 }, 'success', 2000);
  };

  const customizeTotalCount = (data) => {
    return `total Count: ${data.value}`;
  };
  // 새로 고침
  const refreshDataGrid = () => {
    setRefresh((prev) => !prev);
  };

  // 팝업 보임
  const onAddClick = () => {
    setPopupVisible(true);
    const form = formRef.current?.instance;
    form.option('formData.isUse', true);
  };

  // 팝업 숨김
  const hidePopup = () => {
    setPopupVisible(false);
    const form = formRef.current?.instance;
    const file = fileRef.current?.instance;
    form.resetValues();
    file.reset();
  };

  // 삭제 버튼
  const delBtn = async () => {
    try {
      await api.deleteList(checkedIds.current);
      notify({ message: '삭제되었습니다.', width: 300 }, 'success', 2000);
      setRefresh((prev) => !prev);
      // setDelList(delList.filter((data) => data.id !== checkedIds.current.id));
    } catch (e) {
      console.log(e);
      notify({ message: '삭제 실패하였습니다', width: 300 }, 'error', 2000);
    }
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={3000}
    >
      <DataGrid
        dataSource={dataSource}
        showBorders
        rowAlternationEnabled
        repaintChangesOnly
        highlightChanges
        columnAutoWidth
        onSelectedRowKeysChange={(e) => {
          checkedIds.current = e;
        }}
      >
        <MasterDetail enabled component={MasterDetailView} />
        <Selection mode="multiple" />
        <FilterRow visible />
        <Pager allowedPageSizes={pageSizes} showPageSizeSelector />
        <Paging defaultPageSize={20} />
        <Column dataField="id" caption="jobId" />
        <Column caption="action" type="buttons">
          <GridButton onClick={startJob} text="즉시 실행" />
        </Column>
        <Column dataField="name" caption="name" />
        {/* <Column caption="dynamics" minWidth={320} cellRender={ChartCell} /> */}
        <Column dataField="status" caption="status" cellRender={ChangeCell} alignment="center" />
        <Column width={90} dataField="isUse" editorType="dxCheckBox" caption="isUse" />
        <Column dataField="filename" caption="fileName" />
        <Column dataField="lastRun" caption="lastRun" format="yyyy-MM-dd Thh:mm:ss" />
        <Column dataField="nextRun" caption="nextRun" format="yyyy-MM-dd Thh:mm:ss" />
        <Column dataField="note" caption="note" />
        <Column dataField="saveDate" dataType="date" caption="saveDate" format="yyyy-MM-dd Thh:mm" />
        <Toolbar>
          <Item location="before">
            <Button icon="refresh" text="새로고침" type="normal" onClick={refreshDataGrid} />
          </Item>
          <Item location="before">
            <Button text="add new job" type="normal" onClick={onAddClick} />
          </Item>
          <Item location="after">
            <Button text="delete" type="danger" onClick={delBtn} />
          </Item>
        </Toolbar>
        <Summary>
          <TotalItem column="id" summaryType="count" customizeText={customizeTotalCount} alignment="left" />
        </Summary>
      </DataGrid>
      <JobPopup popupVisible={popupVisible} hidePopup={hidePopup} formRef={formRef} fileRef={fileRef} />
      <Cron />
    </SnackbarProvider>
  );
};
export default Workflow;
