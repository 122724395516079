import { ChevronRight, ExpandLess } from '@mui/icons-material';

type ExpandIconPros = {
  isOpen: boolean;
};

const ExpandIcon = ({ isOpen }: ExpandIconPros) =>
  isOpen ? <ExpandLess /> : <ChevronRight />;

export default ExpandIcon;
