import React, { useEffect, useRef, useState } from 'react';
import { Form, Item, ButtonItem, RequiredRule, Label, EmptyItem } from 'devextreme-react/form';
import { Box } from '@mui/material';
import notify from 'devextreme/ui/notify';
import FileUploader from 'devextreme-react/file-uploader';
import { api } from 'lib/api/JobApi';

// const JobTaskListWithProps = (jobId) => <JobTaskList jobId={jobId} />;

const Edit = ({ data }) => {
  const jobId = data.data.id;
  const [formData, setFormData] = useState();
  const formRef = useRef(null);
  const fileRef = useRef([]);

  const handleInputChange = (e) => {
    const changeData = e.component.option('formData');
    setFormData(changeData);
  };

  const onValueChanged = () => {
    const file = fileRef.current.instance.option('value');
    const currentFormRef = formRef.current?.instance;
    currentFormRef.option('formData.filename', file[0]?.name);
  };

  const saveBtn = async () => {
    const file = fileRef.current.instance.option('value');
    try {
      const response = await api.updateList(jobId, { ...formData, file: file[0] });
      console.log('update', response);
      notify({ message: '저장되었습니다.', width: 300 }, 'success', 2000);
    } catch (e) {
      console.log(e);
      // notify({ message: '실패하였습니다', width: 300 }, 'error', 2000);
    }
  };

  const buttonOptions = {
    text: 'save',
    type: 'default',
    useSubmitBehavior: true,
    width: '100px',
    stylingMode: 'contained',
    margin: 10,
    onClick: saveBtn,
  };

  return (
    <Box style={{ margin: 30 }}>
      <Form colCount={2} formData={data.data} onFieldDataChanged={(e) => handleInputChange(e)} ref={formRef}>
        <Item editorType="dxTextBox" dataField="id" label={{ text: 'JobId' }} editorOptions={{ readOnly: true }} />
        <Item editorType="dxTextBox" dataField="name" label={{ text: 'JobName' }}>
          <RequiredRule message="name is required" />
        </Item>
        <Item editorType="dxCheckBox" dataField="isUse" label={{ text: 'IsUse' }} />
        <Item dataField="file" label={{ text: 'File' }}>
          <FileUploader
            ref={fileRef}
            selectButtonText="Select file"
            accept=".py, .zip, .tar, .gz"
            uploadMode="useForm"
            onValueChanged={onValueChanged}
          />
        </Item>
        {/* <EmptyItem/> */}
        <Item editorType="dxTextArea" dataField="note" label={{ text: 'Note' }} /* colSpan={2}/ */ />
        <Item editorType="dxTextBox" dataField="filename" editorOptions={{ readOnly: true }}>
          <RequiredRule message="filename is required" label={{ text: 'FileName' }} />
        </Item>

        {/* job task 숨김 */}
        {/* <Item colSpan={2}>Task List</Item> */}
        {/* <Item component={() => JobTaskListWithProps(jobId)} colSpan={2} /> */}
        {/* <Item caption="TaskCount" dataField="taskCount" disabled /> */}
        <ButtonItem colSpan={2} buttonOptions={buttonOptions} horizontalAlignment="right" />
      </Form>
    </Box>
  );
};

export default Edit;
