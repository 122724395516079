import { styled } from '@mui/material/styles';
import { ListItemButton, ListItemIcon, ListItemText, ListItemIconProps, useTheme, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { sidebarOpend } from 'atoms/layout';
import { ITabTypes, latestTabsStorage, selectedIndexWithStorage, tabsWithStorage } from 'atoms/tab';
import { MenuItem as MenuItemType } from './MenuItems';
import MenuItemsSubList from './MenuItemsSubList';
import ExpandIcon from './ExpandIcon';

interface MenuItemProps {
  menuItem: MenuItemType;
}

const StyledListItemIcon = styled(ListItemIcon)<ListItemIconProps>(({ theme }) => ({
  minWidth: '24px',
  justifyContent: 'center',
  margin: theme.spacing(1),
}));

const MenuItem = ({ menuItem: { name, icon: Icon, subItems, element, isUnique, path } }: MenuItemProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const sidebarOpen = useRecoilValue(sidebarOpend);
  const isNested = subItems && subItems?.length > 0;
  const setSelectedIndex = useSetRecoilState(selectedIndexWithStorage);
  const [tabs, setTabs] = useRecoilState(tabsWithStorage);
  const [latestTabs, setLatestTabs] = useRecoilState(latestTabsStorage);

  const StyeldBox = styled(Box)(() => ({
    ':hover': {
      '.navigation-content': {
        display: 'block'
      }
    }
  }))

  const handleNavClick = () => {
    if (isNested) {
      setOpen((prev) => !prev);
    } else if (element) {
      if (isUnique) {
        const existTabIndex = tabs.findIndex((tab) => tab.id === name);

        if (existTabIndex !== -1) {
          setSelectedIndex(existTabIndex);
          return;
        }
      }
      const tabId = isUnique ? name : `${name}_${Math.random().toString(36).substring(2, 8)}`;
      const newTab: ITabTypes = {
        label: name,
        id: tabId,
        element,
        props: { propTest: 'hello', path },
      };

      setTabs([...tabs, newTab]);
      setSelectedIndex(tabs.length);
    }
  };

  useEffect(() => {
    localStorage.setItem('sidebarOpened', sidebarOpen.toString());
  }, [sidebarOpen])

  return (
    <StyeldBox className="navigation">
      <ListItemButton
        onClick={handleNavClick}
        sx={{
          transition: 'none',
          p: theme.spacing(0, 2, 0, 1),
          fontSize: '2px',
          ':hover': {
            backgroundColor: theme.colors.secondary.main,
            color: theme.colors.alpha.white[100],
          }
        }}
      >
        {Icon && <StyledListItemIcon>
          <Icon />
        </StyledListItemIcon>}

        {sidebarOpen && (
          <>
            <ListItemText primary={name} />
            {isNested ? <ExpandIcon isOpen={open} /> : null}
          </>
        )}
        {/* {sidebarOpen || <MenuItemsSubList isOpen={open} subItems={subItems} anchorEl={anchorEl} />} */}
      </ListItemButton>
      <MenuItemsSubList isOpen={open} subItems={subItems} />
    </StyeldBox>
  );
};
export default MenuItem;
