import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import Schedule from './Schedule';
import Edit from './Edit';
import Run from './detailRun';
import History from './History';

const MasterDetailView = (props) => {
  return (
    <TabPanel>
      <Item title="Edit" component={Edit} data={props.data.data} />
      <Item title="Schedule" component={Schedule} data={props.data.data} />
      <Item title="Run" component={Run} data={props.data.data} />
      <Item title="History" component={History} data={props.data.data} />
    </TabPanel>
  );
};

export default MasterDetailView;
