import { ITabTypes, LatestTabsType } from '../atoms/tab';
import { MENU_ITEMS, MenuItem } from '../layouts/Sidebar/MenuItems';

const findElement = (label: string, MenuItems: MenuItem[]): any => {
  for (let i = 0; i < MenuItems.length; i += 1) {
    if (MenuItems[i].name === label) {
      return MenuItems[i].element
    }

    const x = MenuItems[i].subItems
    if (x !== undefined) {
      const retSubItems = findElement(label, x);
      if (retSubItems !== undefined) {
        return retSubItems
      }
    }
  }

  return undefined
}

export const setIndexStorage = (index: number) => {
  localStorage.setItem('tabIndex', index.toString());
}

export const setTabsStorage = (tabs: ITabTypes[]) => {
  localStorage.setItem('tabs', JSON.stringify(tabs));
}

export function setStorage<T>(data: T, name: string): void {
  localStorage.setItem(name, JSON.stringify(data));
}

export const getStoredIndex = () => {
  const idx = localStorage.getItem('tabIndex')
  if (idx === null || +idx === -1) return null
  return +idx
}

export const getStoredTabs = () => {
  const tabsString = localStorage.getItem('tabs');

  if (tabsString === null) return null

  const storedTabs = JSON.parse(tabsString).map(
    (tab: { label: string, id: string, props?: any }) => (
      { ...tab, element: findElement(tab.label, MENU_ITEMS) }
    )
  )
  const filterStoredTabs = storedTabs.filter((tab: any) => tab.element !== undefined)

  if (filterStoredTabs.length !== storedTabs.length) {
    setTabsStorage(filterStoredTabs)
  }

  if (filterStoredTabs.length === 0) return null

  return filterStoredTabs
}

export const getStoredLatestTabs = () => {
  const latestTabs = localStorage.getItem('latestTabs');

  if (latestTabs === null) return null;

  const objectStoredLatestTabs = JSON.parse(latestTabs);

  if (Object.keys(objectStoredLatestTabs).length === 0) return null;

  return objectStoredLatestTabs;
}
