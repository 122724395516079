import React from 'react';
import { Box } from '@mui/material';
import { Item } from 'devextreme-react/box';
import HeatMap from './HeatMap';

const History = ({ data }) => {
  return (
    <Box style={{ margin: 30 }}>
      <HeatMap id={data.data.id} />
    </Box>
  );
};

export default History;
