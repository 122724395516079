import { Person, AttachMoney, CreditCard, QueryStats, SvgIconComponent, AccountTree } from '@mui/icons-material';

interface AppLauncherOption {
  title: string;
  icon: SvgIconComponent;
  href: string;
}
export interface AppLauncherItem {
  id: string;
  title: string;
  icon: SvgIconComponent;
  href: string;
}
const appLauncherItems: AppLauncherOption[] = [
  { title: 'IAM', icon: Person, href: 'https://who.cloudmt.co.kr/' },
  { title: 'SalesOps', icon: AttachMoney, href: 'https://sale.cloudmt.co.kr/' },
  { title: 'mateBilling', icon: CreditCard, href: 'https://bill.cloudmt.co.kr/' },
  { title: 'mateCDN', icon: QueryStats, href: 'https://stat.cloudmt.co.kr/' },
];
const devAppLauncherItems: AppLauncherOption[] = [
  { title: 'IAM', icon: Person, href: 'https://dev.who.cloudmt.co.kr/' },
  { title: 'SalesOps', icon: AttachMoney, href: 'https://dev.sale.cloudmt.co.kr/' },
  { title: 'mateBilling', icon: CreditCard, href: 'https://dev.bill.cloudmt.co.kr/' },
  { title: 'mateCDN', icon: QueryStats, href: 'https://stat.cloudmt.co.kr/' },
  { title: 'SUPADUPA', icon: AccountTree, href: 'https://dev.supadupa.cloudmt.co.kr/' },
];
const makeAppLauncherItem = (AppLauncherOptions: AppLauncherOption[]): AppLauncherItem[] => {
  return AppLauncherOptions.map((item, idx) => ({ ...item, id: idx.toString() }));
};
export const FLOAT_MENU_ITEMS: AppLauncherItem[] = makeAppLauncherItem(
  process.env.REACT_APP_ENV === 'PRD' ? appLauncherItems : devAppLauncherItems,
);
