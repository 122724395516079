import React, { useEffect, useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { api } from 'lib/api/JobApi';
import Form, { SimpleItem, RequiredRule, Item } from 'devextreme-react/form';
import { Button, CheckBox, FileUploader } from 'devextreme-react';

const JobPopup = (props) => {
  const { popupVisible, hidePopup, formRef, fileRef } = props;

  const handleSubmit = async () => {
    const form = formRef.current?.instance;
    const file = fileRef.current.instance.option('value');
    const formData = { ...form.option('formData') };

    try {
      await api.insertList({ ...formData, file: file[0] });
      notify({ message: '저장되었습니다.', width: 300 }, 'success', 2000);
      form.resetValues();
      const fileReset = fileRef.current?.instance;
      fileReset.reset();
      hidePopup();
    } catch (e) {
      notify({ message: '실패하였습니다', width: 300 }, 'error', 2000);
    }
  };

  const onValueChanged = () => {
    const file = fileRef.current.instance.option('value');
    const form = formRef.current?.instance;
    form.option('formData.filename', file[0]?.name);
  };

  return (
    <div>
      <Popup
        visible={popupVisible}
        onHiding={hidePopup}
        dragEnabled={false}
        hideOnOutsideClick
        showCloseButton={false}
        showTitle
        title="add new job"
        width={460}
        height={480}
      >
        <Form ref={formRef} readOnly={false} showColonAfterLabel /* onFormDataChange={onFomDataChange} */>
          <SimpleItem dataField="name" editorType="dxTextBox">
            <RequiredRule message="name is required" />
          </SimpleItem>
          <SimpleItem dataField="isUse" editorType="dxCheckBox" editorOptions={{ defaultValue: true }}>
            <RequiredRule message="isUse is required" />
          </SimpleItem>
          <SimpleItem dataField="file">
            <FileUploader
              ref={fileRef}
              selectButtonText="Select file"
              accept=".py, .zip, .tar, .gz"
              uploadMode="useForm"
              // onValueChanged={onValueChanged}
              onValueChange={onValueChanged}
            />
            <RequiredRule message="file is required" />
          </SimpleItem>
          <SimpleItem dataField="filename" editorType="dxTextBox">
            <RequiredRule message="filename is required" />
          </SimpleItem>
          <SimpleItem dataField="note" editorType="dxTextArea" />
          <Item>
            <Button
              style={{ marginRight: '10px', marginLeft: '220px' }}
              icon="save"
              text="저장"
              useSubmitBehavior
              onClick={handleSubmit}
            />
            <Button icon="close" text="닫기" onClick={hidePopup} />
          </Item>
        </Form>
      </Popup>
    </div>
  );
};

export default JobPopup;
