import Workflow from 'pages/Workflow/Workflow';
import JobRun from 'pages/Runs/jobRun';
import Dashboard from 'pages/Dashboard/Dashboard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkflowIcon from '@mui/icons-material/Work';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

export interface MenuItem {
  name: string;
  icon?: React.ComponentType;
  path: string;
  id: string;
  depth: number;
  subItems?: MenuItem[];
  element?: (props?: any) => JSX.Element;
  isUnique?: boolean;
}

interface MenuOption {
  name: string;
  icon?: React.ComponentType;
  path: string;
  subItems?: MenuOption[];
  element?: (props?: any) => JSX.Element;
  isUnique?: boolean;
}

const MENU_OPTIONS: MenuOption[] = [
  {
    name: 'Dashboard',
    icon: DashboardIcon,
    path: '/',
    element: Dashboard,
    isUnique: true,
  },
  {
    name: 'Workflow',
    icon: WorkflowIcon,
    path: '/',
    element: Workflow,
    isUnique: true,
  },
  {
    name: 'Runs',
    icon: DirectionsRunIcon,
    path: '/',
    element: JobRun,
    isUnique: true,
  },

  // 참고용 코드
  // {
  //   name: 'Secret',
  //   icon: KeyIcon,
  //   path: 'Iam/',
  //   subItems: [
  //     {
  //       name: 'Secret Manager',
  //       icon: DashboardIcon,
  //       path: 'Iam/SecretManager',
  //       element: Secrets,
  //     },
  //   ]
  // },
];

const makeMenuLevel = (options: MenuOption[], depth = 0): MenuItem[] => {
  return options.map((option, idx) => ({
    ...option,
    id: depth === 0 ? idx.toString() : `${depth}.${idx}`,
    depth,
    subItems: option.subItems && option.subItems.length > 0 ? makeMenuLevel(option.subItems, depth + 1) : undefined,
  }));
};

export const MENU_ITEMS: MenuItem[] = makeMenuLevel(MENU_OPTIONS);
