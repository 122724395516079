import React, { useRef, useEffect, useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useSnackbar } from 'notistack';

// 일반적인 상태에서 토큰 만료시간을 검사하는 하는 주기
// 또한 실제 토큰의 만료시간이 이 값 이하로 남으면 토큰을 리프레쉬한다.
const CHECK_INTERVAL_SEC = 300;
// const CHECK_INTERVAL_SEC = 10;

// 사용자가 마지막으로 클릭을 한 시간 이후 이 시간동안 아무런 동작을 하지 않으면 로그아웃을 한다.
const TOKEN_EXPIRE_SEC = 60 * 60 * 6;
// const TOKEN_EXPIRE_SEC = 20;

// 사용자의 동작이 없을 때 남은 토큰의 만료시간이 시간이 이 시간보다 적어지면 메세지를 출력한다.
const TOKEN_MESSAGE_SEC = 60 * 5;
// const TOKEN_MESSAGE_SEC = 10;

// 사용자가 마지막으로 클릭을 한 시간 이후 이 시간동안 아무런 동작을 하지 않으면 메세지를 출력한다.
const TOKEN_EXPIRE_MESSAGE_SEC = TOKEN_EXPIRE_SEC - TOKEN_MESSAGE_SEC;

const TOKEN_EXPIRE_MESSAGE = '5분 후 자동으로 로그아웃 될 예정입니다. 서비스 이용이 없으면 자동으로 로그아웃됩니다.';

const TOKEN_SANCKBAR_KEY = 'KEYCLOACKSESSION';

const SessionCheckSection = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { keycloak } = useKeycloak();
  const ClickRef = useRef(false);
  const lastClickRef = useRef(Math.floor(new Date().getTime() / 1000));

  const messageTimeoutId = useRef('');
  const tokenExpireId = useRef('');

  // 현재 설정되어있는 setTimeout을 해지하고 다시 설정해준다.
  const tokenExpireSetTimeout = useCallback(() => {
    const curTime = Math.floor(new Date().getTime() / 1000);
    clearTimeout(messageTimeoutId.current);
    messageTimeoutId.current = setTimeout(() => {
      enqueueSnackbar(TOKEN_EXPIRE_MESSAGE, { key: TOKEN_SANCKBAR_KEY, persist: true, variant: 'warning' });
      //   setOpen(true);
    }, (lastClickRef.current + TOKEN_EXPIRE_MESSAGE_SEC - curTime) * 1000);

    clearTimeout(tokenExpireId.current);
    tokenExpireId.current = setTimeout(() => {
      keycloak.logout();
    }, (lastClickRef.current + TOKEN_EXPIRE_SEC - curTime) * 1000);
  }, [keycloak]);

  const onClickCheck = useCallback(() => {
    const oldTime = lastClickRef.current;
    const curTime = Math.floor(new Date().getTime() / 1000);

    closeSnackbar(TOKEN_SANCKBAR_KEY);
    // setOpen(false);
    ClickRef.current = true;
    lastClickRef.current = Math.floor(new Date().getTime() / 1000);

    if (curTime - oldTime > TOKEN_MESSAGE_SEC) {
      tokenExpireSetTimeout();
    }
  }, [tokenExpireSetTimeout]);

  useEffect(() => {
    window.addEventListener('click', onClickCheck, false);
    window.addEventListener('touch', onClickCheck, false);

    const checkEvent = () => {
      keycloak.updateToken(CHECK_INTERVAL_SEC + 1).catch(() => {
        console.log('updateToken fail');
      });

      if (ClickRef.current) {
        ClickRef.current = false;
        tokenExpireSetTimeout();
      }
    };

    setInterval(checkEvent, CHECK_INTERVAL_SEC * 1000);
    checkEvent();
    tokenExpireSetTimeout();
  }, [keycloak, onClickCheck, tokenExpireSetTimeout]);
  return null;
};
export default React.memo(SessionCheckSection);
