import React, { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Tabs, TabsProps } from '@mui/material';

import { selectedIndexWithStorage, tabsWithStorage, ITabTypes, latestTabsStorage } from '../../atoms/tab';

import ContextMenu, { IContextMenuAttribute } from './ContextMenu';
import Tab from './Tab'


const TabsContainer = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column-reverse',
  width: 1, // Need
}))

const StyledTabs = styled(Tabs)<TabsProps>(() => ({
  minHeight: 'auto',
  height: '37px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  alignItems: 'center',
  '.MuiTabs-scrollButtons': {
    height: '100%',
  }
}));

const CloseIconContainer = styled('div')(({ theme }) => ({
  fontSize: 15,
  display: 'flex',
  justifyContent: 'center',
  padding: '2px',
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: theme.colors.alpha.white[30],
  },
}));

const CloseIconButton = (tabKey: string, closeTab: (targetKey: string) => void) => {
  return (
    <CloseIconContainer
      onClick={(e) => {
        e.stopPropagation();
        closeTab(tabKey);
      }}
    >
      <CloseIcon fontSize="inherit" />
    </CloseIconContainer>
  );
};

const TabsBar = () => {
  const [indexWithStorage, setIndexWithStorage] = useRecoilState(selectedIndexWithStorage);
  const [latestTabs, setLatestTabs] = useRecoilState(latestTabsStorage);
  const [tabs, setTabs] = useRecoilState(tabsWithStorage);
  const handleCloseTab = useCallback(
    (targetKey: string) => {
      const targetIndex = tabs.findIndex((tab: ITabTypes) => tab.id === targetKey);
      setTabs((prvTabs) => prvTabs.filter((tab: ITabTypes) => tab.id !== targetKey));

      if (targetIndex !== indexWithStorage) {
        if (targetIndex < indexWithStorage) {
          setIndexWithStorage((prvIndex) => prvIndex - 1);
        }
      } else if (indexWithStorage === tabs.length - 1) {
        setIndexWithStorage((prvIndex) => prvIndex - 1);
      }
    },
    [indexWithStorage, tabs],
  );

  const tabsProps = tabs.map((tab) => ({
    key: tab.id,
    label: tab.label,
  }));

  const onDrageEnd = (droppedItem: any) => {
    if (!droppedItem.destination) return;

    const sourceIndex = droppedItem.source.index;
    const destinationIndex = droppedItem.destination.index;
    const newTabs = [...tabs];
    const selectedId = tabs[indexWithStorage].id;

    const [reorderedItem] = newTabs.splice(sourceIndex, 1);
    newTabs.splice(destinationIndex, 0, reorderedItem);
    const newIndex = newTabs.findIndex((tab: ITabTypes) => tab.id === selectedId);

    setTabs(newTabs);
    setIndexWithStorage(newIndex);
  }

  const [contextMenuAttribute, setContextMenuAttribute] = useState<IContextMenuAttribute | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();

    const { id } = event.target as HTMLElement;

    if (!id) {
      setContextMenuAttribute(null)
      return;
    }

    setContextMenuAttribute(
      contextMenuAttribute === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
          id,
        }
        :
        null,
    );
  };

  const handleContextClose = () => {
    setContextMenuAttribute(null);
  };

  const handleIndexChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    const currentTabs = tabs[value];
    setLatestTabs({ ...latestTabs, [currentTabs.label]: currentTabs.id });
    setIndexWithStorage(value);
  }

  return (
    <TabsContainer onContextMenu={handleContextMenu}>
      <DragDropContext onDragEnd={onDrageEnd}>
        <div>
          <Droppable droppableId='1' direction='horizontal'>
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                <StyledTabs
                  value={indexWithStorage}
                  onChange={handleIndexChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  {tabsProps.map((tabProps, i) => {
                    return (
                      <Tab
                        icon={CloseIconButton(tabProps.key, handleCloseTab)}
                        iconPosition="end"
                        key={tabProps.key}
                        label={tabProps.label}
                        id={tabProps.key}
                        index={i}
                      />
                    )
                  })}
                  {droppableProvided ? droppableProvided.placeholder : null}
                </StyledTabs>
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <ContextMenu
        attribute={contextMenuAttribute}
        contextClose={handleContextClose}
        closeTab={handleCloseTab}
      />
    </TabsContainer>
  );
};

export default TabsBar;
