import { useRecoilState, useRecoilValue } from 'recoil';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import sidebarOpend from 'atoms/layout';
import { tabsState } from 'atoms/tab';
import Appbar from './Header';
import Sidebar from './Sidebar';
import Home from '../pages';
import Footer from './Footer';

const minAppbarHeight = '45px';
interface ContentContainerProps {
  theme?: any;
  hasTab: boolean;
}

interface MainProps {
  open: boolean
}

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<MainProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '100vh',
  overflowX: 'hidden',
  width: '100%',
}));

const ContentContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasTab',
})(({ theme, hasTab }: ContentContainerProps) => ({
  minHeight: '600px',
  margin: `${minAppbarHeight} 10px 0px`,
  padding: theme.spacing(2),
  ...(hasTab && {
    background: theme.palette.background.paper,
  }),
}));

const Layout = () => {
  const [tabs] = useRecoilState(tabsState);

  const hasTab = !!tabs.length;
  const open = useRecoilValue(sidebarOpend);

  // 권한 할당
  // const setUserAuth = useSetRecoilState(userKeycloakAuth);
  // useEffect(() => {
  //   setUserAuth(userAuth())
  // }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <Appbar />
      <Sidebar />
      <Main open={open}>
        <ContentContainer hasTab={hasTab}>
          <Home />
        </ContentContainer>
        {/* <Footer /> */}
      </Main>
    </Box>
  );
};
export default Layout;
