import axios from 'axios';

// axios 인스턴스를 만들 때 구성 기본 값 설정
const instance = axios.create({
  baseURL: 'https://supadupa-api-dev.nicemeadow-ed13bb66.koreacentral.azurecontainerapps.io/',
  timeout: 30000,
});

async function getReq(params) {
  const res = await instance({
    method: 'get',
    url: params,
  });
  return res.data;
}

async function delReq(api, params) {
  await instance({
    method: 'delete',
    url: api,
    data: params, // 배열 형태로 삭제
  });
}

async function insertReq(api, value) {
  const res = await instance({
    method: 'post',
    url: api,
    data: value,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
}

const updateReq = async (api, params) => {
  const { data } = await instance({
    method: 'put',
    url: api,
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  // response.status 가 400이면 실패
  return data;
};

// 스케줄

const delSched = async (api, id) => {
  const { data } = await instance({
    method: 'delete',
    url: api,
    data: JSON.stringify([id]),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
};

const insertSched = async (api, params) => {
  const result = await instance({
    method: 'post',
    url: api,
    data: JSON.stringify([params]),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  return result;
};

const updateSched = async (api, params) => {
  const { data } = await instance({
    method: 'put',
    url: api,
    data: JSON.stringify([params]),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  return data;
};

export const api = {
  // get jobList
  getJobList: (offset, limit) => {
    return getReq(`Jobs/?offset=${offset}&limit=${limit}`);
  },
  // job id detail
  getList: (id) => {
    return getReq(`Jobs/${id}`);
  },
  // job delete
  deleteList: (params) => {
    return delReq(`Jobs`, params);
  },
  // job add
  insertList: (value) => {
    return insertReq(`Jobs`, value);
  },
  // job update
  updateList: (id, params) => {
    return updateReq(`Jobs/${id}`, params);
  },
  // get job taskList
  getJobTask: (id, offset, limit) => {
    return getReq(`Jobs/${id}/JobTasks?offset=${offset}&limit=${limit}`);
  },
  // get scheduleList
  getSchedule: (id) => {
    return getReq(`Jobs/${id}/Schedules`);
  },
  // schedule insert
  insertSchedule: (id, params) => {
    return insertSched(`Jobs/${id}/Schedules`, params);
  },
  // schedule update
  updateSchedule: (id, params) => {
    return updateSched(`Jobs/${id}/Schedules`, params);
  },
  // schedule delete
  delSchedule: (id) => {
    return delSched(`Schedules`, id);
  },
  // get dashboard card
  getCard: () => {
    return getReq('Statistic');
  },

  // 최근 10 런
  getRecentRun: (id) => {
    return getReq(`Jobs/${id}/RecentRuns?limit=10`);
  },

  // 전체 런탭 런
  getJobRuns: (jobIds, startDate, endDate) => {
    let jobIdsQueryString = '';
    if (jobIds?.length > 0) jobIdsQueryString = `&jobIds=${jobIds.join('&jobIds=')}`;

    return getReq(`Runs?startDate=${startDate}&endDate=${endDate}${jobIdsQueryString}`);
  },
  // history
  getJobRunsHistory: (id, startDt, endDt) => {
    return getReq(`Runs?jobIds=${id}&startDate=${startDt}&endDate=${endDt}`);
  },

  // 실행중인 런
  getActiveRuns: () => {
    return getReq(`Runs?states=Running`);
  },
  // 대시보드 투데이 런
  getTodayRuns: (startDate) => {
    return getReq(`Runs?startDate=${startDate}`);
  },
  // 잡 즉시 실행
  startJob: (id) => {
    return insertReq(`Jobs/${id}/Start`);
  },
};

export default instance;
