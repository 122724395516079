import { useState, MouseEvent } from 'react';
import { IconButton, IconButtonProps, Menu, Box, BoxProps, Typography, useTheme } from '@mui/material';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { FLOAT_MENU_ITEMS } from './AppLauncherItems';
import { sidebarOpend } from '../../atoms/layout';
import AppLauncherItemsList from './AppLauncherItemsList';

interface AppLauncherIconProps extends IconButtonProps {
  open?: boolean;
}

const IconButtonContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1, `calc(${theme.spacing(1)} + 2px)`),
}));

const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'open' })<AppLauncherIconProps>(
  ({ theme, open }) => ({
    borderRadius: '0',
    color: theme.palette.primary.main,
    ...(open && {
      color: theme.palette.warning.dark,
    }),
    '&:hover': {
      color: theme.palette.warning.dark,
    },
  }),
);

const AppLauncher = () => {
  const theme = useTheme();
  const isOpend = useRecoilValue(sidebarOpend);
  const [serviceOpen, setServiceOpen] = useState<null | HTMLElement>(null);
  const open = Boolean(serviceOpen);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setServiceOpen(event.currentTarget);
  };
  const handleClose = () => {
    setServiceOpen(null);
  };
  return (
    <>
      <IconButtonContainer>
        <StyledIconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          disableRipple
          sx={{ p: 0 }}
          open={open}
        >
          <WidgetsIcon sx={{ m: '8px' }} />
          {isOpend && <Typography>서비스</Typography>}
        </StyledIconButton>
      </IconButtonContainer>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          style: {
            background: theme.colors.primary.main,
          },
        }}
        anchorEl={serviceOpen}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 250,
            borderRadius: 0,
          },
        }}
        style={{ backdropFilter: 'blur(0)' }}
      >
        <AppLauncherItemsList AppLauncherItem={FLOAT_MENU_ITEMS} />
      </Menu>
    </>
  );
};

export default AppLauncher;
